@keyframes marquee {
	0% { transform: translateX(100%); }
	100% { transform: translateX(-100%); }
}

.breaking-news-parent {
	position: absolute;
	bottom: 7%;
	width: 100%;
	border-radius: unset;
	margin-bottom: unset;

	.breaking-news {
		animation: marquee 60s linear infinite;
		z-index: 1000000;
		white-space: nowrap;
	}
}