.start-view {
	&.has-breaking-news {
		margin-top: 0 !important;
	}
}

.map-channel {
	.card-body {
		padding: unset;

		.leaflet-control-container {
			display: none;
		}
	}
}
