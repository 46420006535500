.sidebar {
	border-top-left-radius: 7px;
	background: #ffffff;
	position: fixed;
	right: -1px;
	top: 34%;
	width: 14vw;
	align-self: center;
    box-shadow: 4px 4px 16px rgba(34, 83, 120, 0.2);
    
	.sidebar-inner {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 2vh;
		height: 100%;
	}

	a {
		color: #11727C;
		display: block;
		font-size: 2.2vh;
		word-wrap: break-word;
		width: 100%;
		padding-top: 1vh;
		padding-bottom: 1vh;
        margin-bottom: 1vh;
        
		&.active {
			display: block;
			font-size: 2.2vh;
			word-wrap: break-word;
            text-decoration: underline;
            font-weight: bold;
		}

		&:hover {
			text-decoration: none;
		}
	}

	span.sidebar-header {
		background: #225378;
		display: block;
		color: white;
		text-decoration: none;
		font-size: 25px;
		padding: 0.75vh 2vh;
	}

    .sidebar-item-inner-icon{
        margin-right: 10px;
        
        img{
            margin-top: -6px;
            width: 34px;
        }
    }
}

.other-events-link {
	color: white;
	display: block;
	text-decoration: underline;
	font-weight: bold;
	font-size: 25px;
	padding: 1em 1em 2em 2em;
	word-wrap: break-word;
	position: fixed;
	right: 1em;
	bottom: 90px;
}