.leaflet-container {
	height: 79%;
	width: 86vw;
}

// Colors

$activity-category-color: #762fad;
$health-services-category-color: #d1193f;
$attraction-category-color: #225378;
$coworking-category-color: #32464d;
$cycling-category-color: #437611;
$catering-category-color: #ee9816;
$culture-category-color: #58ae96;
$regional-offers-category-color: #01ace2;
$hotels-category-color: #a7c201;
$hiking-category-color: #7d4c30;
$location-category-color: #6b6e71;

.showcase-map {
	position: fixed;
	left: -1px;
}

.showcase-categories {
	height: 79%;
	position: absolute;
	width: 15%;
	right: -1px;

	&.has-breaking-news {
		height: 77%;
	}

	.list-group-item {
		border-radius: unset !important;
		font-size: 14pt;
		padding-top: 10pt;
		border-bottom: 1.5px solid #c4c4c4;
		border-top: unset !important;

		img {
			height: 50px;
			margin-right: 10px;
		}

		&:last-child {
			border-bottom: unset !important;
		}

		&.is-active {
			&.activity {
				background-color: $activity-category-color;
				color: white;
			}

			&.health {
				background-color: $health-services-category-color;
				color: white;
			}

			&.attraction {
				background-color: $attraction-category-color;
				color: white;
			}

			&.coworking {
				background-color: $coworking-category-color;
				color: white;
			}

			&.catering {
				background-color: $catering-category-color;
				color: white;
			}

			&.local {
				background-color: $regional-offers-category-color;
				color: white;
			}

			&.culture {
				background-color: $culture-category-color;
				color: white;
			}

			&.cycling-layer {
				background-color: $cycling-category-color;
				color: white;
			}

			&.hotels {
				background-color: $hotels-category-color;
				color: white;
			}

			&.hiking-layer {
				background-color: $hiking-category-color;
				color: white;
			}

			&.location-pin {
				background-color: $location-category-color;
				color: white;
			}
		}
	}
}

.leaflet-popup-content-wrapper {
	border-radius: 0px;
}

.leaflet-popup-close-button {
	display: none;
}

.leaflet-control-attribution {
	position: fixed !important;
	left: 0vw !important;
	top: 12vh !important;
	pointer-events: none;
}

.leaflet-control-zoom {
	position: fixed;
	bottom: 15vh;
	right: 17vw;
	border: unset !important;

	a {
		width: 3vw !important;
		height: 3vw !important;
		line-height: 3vw !important;
		-webkit-tap-highlight-color: transparent;

		&::selection {
			background-color: white;
		}
	}

	.leaflet-control-zoom-in {
		margin-bottom: 3vh;
	}
}

.leaflet-popup-content {
	/* Adjust the list item display to the map */
	a {
		color: black;
	}

	a:hover {
		text-decoration: none;
		color: inherit;
	}

	.btn {
		background: #11727C;
		border-color: #11727C;
		color: white;
		font-weight: bold;
		font-size: 1vh;
		line-height: 21px;
		box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
		padding: 0.5em;
		width: 100%;
		text-transform: uppercase;
	}
}

.popup-qrcode-parent {
	margin-top: 1vh;
}

.popup-qrcode {
	margin-top: 1vh;
	text-align: center;
}
