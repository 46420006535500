@media (max-width: 1500px) {
	.detail-page-parent.row {
		margin-left: 0vh !important;
	}

	.main {
		padding-left: 5px !important;
	}

	.detail-meta {
		display: none;
	}

	.detail-page-body {
		height: 100%;
	}
}
