.loading-indicator {
	margin: 0 auto;
	color: #8080801a;
	top: 45%;
	position: relative;
}

.fullscreen-loading-indicator-parent {
	height: 100%;
	width: 100%;
	position: relative;
}

.fullscreen-loading-indicator {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	color: #8080801a;
}
