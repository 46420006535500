.list-page {
	margin-right: 16%;
	margin-top: 1vh;
	margin-left: 1vw;

	.badge {
		text-align: center;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 5px;
		font-weight: bold;
		margin-right: 1em;
		margin-top: 1em;
		margin-bottom: 1.5em;
		border-radius: unset;
		height: 4vh;
		padding-top: 1.1vh;
		font-size: 1.5vh;
	}

	.list-channel-badge {
		background: linear-gradient(0deg, rgba(31, 130, 192, 0.2), rgba(31, 130, 192, 0.2)), #225378;
		color: white;
		margin-right: 1em;
		margin-top: 1em;
	}

	.badge-light {
		border: 1px solid #225378;
		color: #225378;
	}

	.list-item-row {
		padding-left: unset;

		.col-lg-3 {
			padding-left: 0;
		}
	}

	h1 {
		font-weight: 300;
		margin-bottom: 1rem;
	}
}

.events-list {
	.list-page {
		margin-right: unset !important;
	}
}

.list-item {
	background: white;
	color: black;
	margin-bottom: 2vh;
	-webkit-box-shadow: 4px 4px 16px rgba(34, 83, 120, 0.2);
	box-shadow: 4px 4px 16px rgba(34, 83, 120, 0.2);
	height: 7.5vw !important;
	display: block;

	.list-item-text-content-container {
		padding: 20px 0 20px 3px;

		h4 {
			font-family: "Lora", serif;
			white-space: nowrap;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.col-lg-12 {
		.list-item-text-content-container {
			padding: 20px 18px 20px 3px;
		}
	}

	&:active,
	&:hover {
		text-decoration: none;
		color: inherit;
	}

	.list-item-image {
		width: 100%;
		height: 7.5vw !important;
		vertical-align: middle;
		object-fit: cover;
	}

	.list-announcement-qr-code {
		width: 5vw !important;
		height: 5vw !important;
		margin-left: -0.5vw;
		margin-top: 1.25vw;
	}

	.list-item-meta {
		font-weight: 300;
		font-size: 1.3vh;
		color: #252525;
		font-style: italic;
		margin-left: -0.1vw;
	}

	.row {
		padding-left: 1.5vh;
	}

	&.next-page-item {
		text-align: center;
		padding-top: 3vw;
		font-size: 1.8vh;
		vertical-align: middle;
		text-transform: uppercase;
		font-weight: bold;
		color: #11727c;

		&:hover {
			text-decoration: none;
			color: inherit;
		}

		img {
			height: 1.8vh;
			margin-left: 0.5em;
			margin-top: -4px;
			object-fit: initial;
		}
	}

	a {
		display: contents;
		color: black;
	}

	.list-item-title {
		word-break: keep-all;
		font-size: 1.5vh;
		font-weight: normal;
	}

	.leisure-icon {
		color: #ca6e02;
		height: 2vh;
		margin-left: 0.2vw;
		margin-top: -0.2vw;
		vertical-align: middle;
	}

	p {
		margin-bottom: unset;

		&.list-item-text-content {
			color: #252525;
			font-weight: 300;
		}
	}
}

.list-item-text {
	overflow: hidden;
	text-overflow: ellipsis;

	b {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 1.7vh;
	}

	p {
		&.list-item-text-meta {
			margin-bottom: unset;
			color: #252525;
			font-style: italic;
			font-size: 10pt;
			margin-left: -1pt;
		}

		&.list-item-text-content {
			font-size: 11pt;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #252525;
		}

		&.list-item-leisure-content {
			font-size: 10pt;
		}

		&.announcement-content {
			font-size: 9pt;
			color: #252525;
		}
	}
}
