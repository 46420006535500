.card {
	border: unset !important;
    border-radius: 0 !important;
    box-shadow: 4px 4px 16px rgba(34, 83, 120, 0.2);
	margin-bottom: 30px;
	height: 34vh;
	border-style: none !important;

	.card-img-top {
		border-style: none !important;
		height: 18.5vh;
		border-radius: unset;
		object-fit: cover;
	}

	.card-title {
		font-family: 'Lora', serif;
		font-style: normal;
		font-weight: 500;
		font-size: 1.85vh;
		line-height: 19px;
		margin-bottom: 1vh;
		margin-top: 0.2vh;
        text-align: center;
	}

	.card-body {
		border-style: none !important;
	}

	.card-text {
		padding-bottom: unset;

		span.main-text {
			white-space: break-spaces;
			overflow: hidden;
			text-overflow: ellipsis;
			font-family: sans-serif, Lato;
			font-style: normal;
			font-weight: normal;
			font-size: 1.3vh;
			height: 4vh;
			display: inline-block;
			margin-top: 0.5vh;
            display: none;
		}
	}

	.card-header {
		text-align: center;
		text-transform: uppercase;
		background: #225378;
		color: white;
		font-size: 1.5vh;
        font-weight: 400;
		border-bottom: unset;
        border-radius: 0 !important;
        padding: 0.6rem 1.25rem;
        letter-spacing: 0.15em;
	}

	.card-footer {
		text-align: center;
		border: unset;
		padding-top: unset;
		padding-bottom: unset;
		background-color: white;
		position: absolute;
		bottom: 10px;
		width: 100%;
		height: 4.5vh;

		.row {
			height: unset !important;
		}

		.btn {
			font-size: 1.2vh;
			line-height: 21px;
			padding: 0.5em;
			width: 100%;
            letter-spacing: 0.1em;
		}

		.btn-primary {
			background: #11727C;
			border-color: #11727C;
			color: white;
			font-weight: bold;
		}

		.btn-secondary {
			background: #fff;
			border: 1px solid #11727C;
			color: #11727C;
			font-weight: bold;
		}
	}

	.map-parent {
		padding: unset;
	}
}

.highlighted {
	.card-header {
		background: #225378;
		color: white;
	}
}

.map-channel {
	.card-body {
		padding-top: unset !important;

		.leaflet-container {
			width: 100%;
			height: 100%;
			z-index: 100000;
		}

		.leaflet-tooltip {
			font-weight: bold;
			font-size: 14px;
			background: unset;
			border: unset;
			box-shadow: unset;
			color: #11727C;
			text-shadow: 3px 3px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
		}
	}

	.card-footer {
		margin-top: 1vh;
		height: 4vh;

		.map-channel-button {
			z-index: 100000;
			position: relative;
		}
	}
}

.bullets-parent {
	position: relative;
	text-align: center;
	margin-top: -2px;


	.bullet {
		width: 5px;
		height: 5px;
		display: inline-block;
		border-radius: 5px;
		background: #225378;
		margin-right: 5px;
		margin-left: 5px;
		outline: none;
	}

	.bullet-active {
		width: 8px;
		height: 8px;
		border-radius: 8px;
	}
}

.emergency-service-text {
	font-size: 11pt;
}

.emergency-footer {
	font-size: 11pt;
}

.emergency-services-footer {
	text-align: left;

	.fa-phone-alt {
		margin-top: 0.4em;
		font-size: 19pt;
		color: #EB7F00;
	}
}

.emergency-services-body {

	.card-title {
		text-align: left;
        
        &.emergency-call-title{
         margin-top: 30px;   
        }
	}
}

.emergency-channel-link {
	color: initial;

	&:hover,
	&:active {
		color: initial;
		text-decoration: none;
		outline: none;
	}
}

.emergency-general {
	display: table;
	text-align: left;
	font-size: 10pt;
}

.emergency-general-phonenumber {
	display: table;
	font-weight: bold;
	text-align: left;
	font-size: 10pt;
}

.channel-box-map {
	z-index: 100000000;
	width: 100% !important;
	height: 88% !important;
	position: absolute !important;
}