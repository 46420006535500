.detail-page-parent.row {
	margin-left: 3vh !important;
}

.detail-page-body {
	/* generic wrapper independen of the content */
	background: white;
	-webkit-box-shadow: 16px -16px 0px 0px rgba(255, 255, 255, 0.53);
	box-shadow: 16px -16px 0px 0px rgba(255, 255, 255, 0.53);
	margin-top: 5%;
	padding: 2vh;
	height: 65vh;

	.detail-meta {
		color: #494949;
		font-size: 1.8vh;
		font-weight: 300;
		margin-bottom: 1vh;

		a {
			color: #11727c;
			font-weight: bold;
			padding-bottom: 2px;
			border-bottom: 1px solid #225378;

			&:hover {
				text-decoration: none;
			}
		}

		.detail-meta-author {
			color: black;
			font-weight: 400;
		}

		.detail-meta-date::before {
			content: "·";
			font-weight: bold;
			padding-left: 0.3em;
			padding-right: 0.2em;
		}
	}

	h1 {
		font-size: 3vh;
		font-family: "Lora", serif;
		margin-bottom: 0.5vh;
	}

	.badge {
		background-color: #225378;
		color: white;
		text-align: center;
		padding-bottom: 5px;
		display: block;
		width: fit-content;
		padding-left: 1vw;
		padding-right: 1vw;
		margin-bottom: 2vh;
		position: absolute;
		top: -2vh;
		left: -1vw;
		border-radius: unset;
		height: 4vh;
		font-size: 1.8vh;
		padding-top: 1vh;
		font-weight: unset;
		margin-left: -3px;
	}

	.text-content {
		overflow-y: scroll;
		overflow-x: hidden;
		font-size: 2vh;
		line-height: 3.25vh;
		margin-top: 2vh;
		width: auto;
		height: 45vh;
	}
}

.event-qrcode-parent {
	margin-top: 1vh;

	.qr-code-code {
		text-align: left;

		canvas {
			margin-left: 1vw;
		}
	}

	.qr-code-text {
		text-align: right;
		font-size: 2vh;

		span {
			position: absolute;
			bottom: 1px;
			display: block;
			right: -18px;
		}
	}
}

.detail-openingtimes,
.detail-contact {
	white-space: pre-wrap;
}

.detail-sidebar {
	font-size: 1.5vh;
	padding-left: 5vh !important;

	.text-details {
		overflow-y: auto;
		max-height: 57vh;
		a {
			color: #11727c;
			padding-bottom: 2px;
			border-bottom: 1px solid #225378;
			font-weight: bold;

			&:hover {
				text-decoration: none;
			}
		}
	}

	p {
		margin-bottom: 3vh;
	}
}

.leisure-qrcode-parent {
	background: white;
	text-align: right;
	padding-top: 1em;
	padding-right: 0px !important;
	z-index: 100000000;

	canvas {
		margin-left: 1em;
	}
}

/* Event specific styling */
.event-body-row,
.leisure-body-row,
.article-body-row {
	padding-left: 1vh;
	padding-right: 1vh;
}

.event-body {
	.badge {
		display: block;
	}

	.event-content.qr-code {
		height: 460px;
	}

	.event-body-row {
		padding-top: 4vh;
	}

	.event-body-row .col-lg-8 {
		padding-right: 2vw;
	}
}

.leisure-body {
	padding-top: 4vh;

	.badge {
		display: block;
	}

	.leisure-content.qr-code {
		height: 460px;
	}
}

.detail-backdrop {
	position: fixed;
	left: -1px;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: center !important;
}
