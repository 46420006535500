@import "~/node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "src/mobile.scss";

html, 
body {
	touch-action: none;
}

html,
body,
.container-fluid,
#root {
	height: 100%;
	overflow: hidden;
	padding-left: unset;
	padding-right: unset;
	font-family: 'Lato', sans-serif;
}

.container-fluid {
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: center !important;
}

.header-content {
	margin-left: 1vw;
	margin-right: 2vw;
	margin-top: 1vh;
	margin-bottom: 2vh;
}

.header {
	background-color: white;
	border-top: 0.5vh solid white;
	height: 12vh;
	border-bottom: 1px solid #c4c4c4;

	.header-column-title {
		font-size: 1.5vh;
	}
    
    .wifi-text .header-column-title {
        display: block;
        text-align: right;
    }
}

.alert {
	background: #EB7F00;
	border-color: #EB7F00;
	color: white;
	border: unset;
	height: 4vh;
	padding-top: 0.4vh;
	font-size: 2vh;
}

.breaking {
	.col {
		.alert {
			margin-bottom: unset;
			border-radius: unset;
		}
	}
}

.weather-and-time-container,
.wifi-box-parent {
	margin-top: 1.5vh;
}

.wifi-box-parent  {
	.wifi-text-sub {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 2.2vh;
	}
}

.weather-forecast {
	margin-top: 0vh !important;

	.col:nth-child(4) {
		border-right: 1px solid #979797;
		margin-right: 1em;
	}

	.weather-api-source {
		color: #c4c4c4;
		width: 100%;
		display: inline-block;
		text-align: right;
		font-size: 1vh;
		margin-top: 0.6vh;
		margin-right: 8vw;
	}
}

.last-today-forecast::after {
	border-right: 1px solid #979797;
	position: absolute;
	left: 0;
	content: " ";
	display: block;
	height: 7.2vh;
	width: 100%;
	top: 0px;
	margin-left: -0.5vw;
}

.main {
	height: 88vh;
	padding: 3vh 5vw;
    background: #5A93DF;
    background-image: linear-gradient(206deg, #AFDBE0 5%, rgba(88,174,150,0.15) 100%);  
    
    &.leisure-list, &.events-list, &.articles-list, &.announcement-list{
        padding: 3vh 5vw 3vh 7vw;
    }
}

.main.articles-detail, .main.leisure-detail, .main.events-detail, .main.map{
    padding: 0 5vw;
}

.footer {
	height: 7%;
	z-index: 100000;
	background: white;
	position: fixed;
	bottom: 0px;
	width: 100%;

	:first-child {
		height: 100%;
	}
}

.banner-container-parent {
	border-left: 1px solid #f1f1f1;
}

.page-spinner {
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;

	svg {
		color: white;
	}
}

.page-404 {
	width: 100%;
	height: 100%;

	div {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		h1 {
			font-family: 'Lato', sans-serif;
			font-weight: 300;
		}
	}
}

/*
Helper
* Size factor: 1,42
*/

.bold-text {
	font-weight: bold;
}

.subtext {
	font-size: 2.2vh;
	font-weight: bold;
}

a.no-highlight,
a.no-highlight:hover,
a.no-highlight:active {
	color: initial;
	text-decoration: initial;
}
