.wifi-qr-code {
	width: 9vh !important;
	height: 9vh !important;
}

.wifi-info-icon {
	margin-top: 0.5vh;
	height: 3vh;
}

.close-wifi-info-box-icon {
	height: 2vh;
	position: absolute;
	top: 1vh;
	right: 1vh;
}

.wifi-text-sub {
	span {
		display: block;
		width: 100%;
		text-align: right;
	}
}

.modal {
	top: 10vh !important;
	right: 5vw !important;
	z-index: 100000002;
}

.modal-backdrop {
	z-index: 100000001;
}

.wifi-modal .modal-dialog {
	margin: unset;
	right: 13vw;
	position: fixed;
	top: 13vh;
}

.modal-content {
	border: unset;
	border-radius: unset;

	.modal-body {
		padding: unset;

		div {
			padding-left: 2vw;
			padding-right: 2vw;
		}

		div:not(:first-of-type) {
			margin-top: 1vh;
		}

		div:first-of-type {
			margin-top: 2vh;
		}

		div:last-of-type {
			margin-bottom: 2vh;
		}
	}
}

.qr-parent {
	margin-top: -1.5vh;
}