.text-and-logo-banner,
.text-only-banner,
.logo-only-banner {
	display: table-cell;
	text-align: left;
	width: 100%;
	padding-right: 0.5em;
	padding-left: 2em;
	word-break: break-word;
	color: grey;
	font-size: 1.25vh;
	vertical-align: middle;

	img {
		height: 5vh !important;
		margin-top: 1vh;
		margin-bottom: 1vh;
		margin-right: 1vh;
	}
}

.logo-only-banner {
	text-align: center;
}

.text-only-banner {
	margin-top: 1.5vh;
	vertical-align: middle;
}

.banner-container-parent {
	height: 100% !important;

	.row {
		padding-left: 1vw;
		padding-right: 1vw;
	}
}

.ad-content-logo {
	padding: 0.5vh;
}

.ad-only-logo {
	text-align: center;
	height: 14%;
	padding: 1vh;

	img {
		padding: unset !important;
	}
}

.ad-content-text {
	font-size: 1.3vh;
	display: flex;
	align-items: center;
	color: grey;
}
