.header-logo-parent {
	padding-left: 1vw;
	font-size: 1.5vh;

	.header-logo-name {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 2.2vh;
	}

	.header-logo {
		height: 9vh;
		margin-right: 1vw;
	}

	.header-and-logo {
		margin-top: 1.5vh;
	}
}

.header-link {
	color: initial;

	&:hover,
	&:active {
		color: initial;
		text-decoration: none;
		outline: none;
	}
}