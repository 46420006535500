.nav-button {
	background: #fff;
	height: 13vh;
	width: 13vh;
	border-radius: 120px;
	padding-left: 6vh;
	box-shadow: 5px 6px 9px rgba(0, 0, 0, 0.25);
	z-index: 1000;
	left: -4vh;
	padding-right: 0.5vw;
}

.home-button {
	position: fixed;
	left: -6vh;
	top: 35%;

	&.no-back-button {
		top: 45%;
	}
}

.back-button {
	position: fixed;
	left: -6vh;
	top: 50%;
}
